import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontSize: 16,
  },
});

export function Layout(props) {
    return (
      <>
        <CssBaseline />
        <ThemeProvider theme={theme}>
        <Container>
          {props.children}
        </Container>
        </ThemeProvider>
      </>
    );
}
