import React from 'react';
import DinnerList from './DinnerList';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  chip: {
    margin: theme.spacing(0.5),
  }
}));

export function Home() {
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.text} variant="h5" gutterBottom>
          Dinner Time <Chip
              label="v1.0"
              className={classes.chip}
              color="secondary"
            />
        </Typography>
      <DinnerList></DinnerList>
    </div>
  )
}