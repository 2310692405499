import React, { useEffect, useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import RefreshIcon from '@material-ui/icons/Refresh';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import useLocalStorage from '../hooks/useLocalStorage';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 360,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
      },
  }));

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

export default function DinnerList() {
    const classes = useStyles();
    const [dinners, setDinners] = useLocalStorage('dinners', []);
    const [checked, setChecked] = useLocalStorage('checked', [0,1,2,3,4,5,6]);
    const daysOfWeek = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
  
    useEffect(() => {
      if (dinners.length === 0) fetchDinners(true);
    }, []);
  
    async function fetchDinners(allDays) {
      setBackdropOpen(true);

      const days = [0,1,2,3,4,5,6].filter(n => allDays || !checked.includes(n));
      const data = await getDinners(days);

      setBackdropOpen(false);

      let newDinners = [...dinners];
  
      // only update what we need
      for (let i = 0; i < days.length; i++) {
        const day = days[i];
        newDinners[day] = data[i];
      }
      
      setDinners(newDinners);
    }
  
    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      setChecked(newChecked);
    };
  
    const handleCopyClick = (ingredients) => {
      navigator.clipboard.writeText(ingredients);
      setSnackbarOpen(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setSnackbarOpen(false);
    };
  
    const fabStyle = {
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 20,
      left: 'auto',
      position: 'fixed',
  };

  const fabClearStyle = {
    margin: 0,
    top: 'auto',
    left: 20,
    bottom: 20,
    right: 'auto',
    position: 'fixed',
  };

  const fabAllStyle = {
    margin: 0,
    top: 'auto',
    left: 90,
    bottom: 20,
    right: 'auto',
    position: 'fixed',
  };
  
    return (
      <>
      <List className={classes.root}>
        {daysOfWeek.map((day, i) => {
          const labelId = `checkbox-list-label-${i}`;
  
          return (
            <Fragment key={i}>
            <ListItem role={undefined} dense button onClick={handleToggle(i)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(i) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={dinners.length > i && dinners[i].name} secondary={day} />
              {dinners.length > i && dinners[i].ingredients &&
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="copy" onClick={() => handleCopyClick(dinners[i].ingredients)}>
                  <FileCopyIcon />
                </IconButton>
              </ListItemSecondaryAction>}
            </ListItem>
            {i < daysOfWeek.length - 1 &&
            <Divider />}
            </Fragment>
          );
        })}
      </List>
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Fab color="secondary" aria-label="refresh" style={fabStyle} onClick={() => fetchDinners()} disabled={checked.length === 7}>
        <RefreshIcon />
      </Fab>
      <Fab color="primary" aria-label="clear" style={fabClearStyle} onClick={() => setChecked([])}>
        <CheckBoxOutlineBlankIcon />
      </Fab>
      <Fab color="primary" aria-label="clear" style={fabAllStyle} onClick={() => setChecked([0,1,2,3,4,5,6])}>
        <CheckBoxIcon />
      </Fab>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Ingredients copied!
          </Alert>
        </Snackbar>
    </>
    );
  }
  
  async function getDinners(days) {
    const response = await fetch(`dinners?daysRequired=${days.join('&daysRequired=')}`);
    const data = await response.json();
    return data;
  }